import React from 'react';
import isEmpty from 'lodash/isEmpty';
import SkillTag from './SkillTag';
import SectionHeader from '@components/SectionHeader';
import { LEVEL_1, LEVEL_2, LEVEL_3 } from '@helpers/constants';

const assignLevelNum = (levelName) => {
  switch (levelName) {
    case LEVEL_1:
      return 1;
    case LEVEL_2:
      return 2;
    case LEVEL_3:
      return 3;
    default:
      return 0;
  }
};

const SkillTags = ({ skills = [{ name: '', level: '' }] }) => {
  const isSkillsNotArray = !Array.isArray(skills);
  if (isSkillsNotArray) return null;

  const isSkillsEmpty = isEmpty(skills);
  if (isSkillsEmpty) return null;

  skills.sort((skillA, skillB) => {
    const levelNumA = assignLevelNum(skillA?.level);
    const levelNumB = assignLevelNum(skillB?.level);

    if (levelNumA > levelNumB) {
      return -1;
    }
    if (levelNumA < levelNumB) {
      return 1;
    }
    return 0;
  });

  return (
    <div className={'skill-tags-container'}>
      <SectionHeader label={'Skills you will gain'} />
      {skills.map((skill, idx) => (
        <SkillTag {...skill} key={idx} />
      ))}
    </div>
  );
};

export default SkillTags;
