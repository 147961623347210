import React from 'react';
import DataWrapper from '@components/DataWrapper';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import isEmpty from 'lodash/isEmpty';
const CourseMobileInfo = ({
  registrationDeadline,
  startDate,
  endDate,
  level,
  pricingModel,
  duration,
  location,
}) => {
  const hasNoRegistrationDeadline = isEmpty(registrationDeadline);
  const hasNoStartDate = isEmpty(startDate);
  const hasNoEndDate = isEmpty(endDate);
  const hasNoLocation = isEmpty(location.name);
  const hasNoDuration = isEmpty(duration);
  const hasNoLevel = isEmpty(level);
  const hasNoPricingModel = isEmpty(pricingModel);
  const hasNoContent =
    hasNoRegistrationDeadline &&
    hasNoStartDate &&
    hasNoEndDate &&
    hasNoLocation &&
    hasNoDuration &&
    hasNoLevel &&
    hasNoPricingModel;

  return (
    <DataWrapper
      empty=""
      isEmpty={hasNoContent}
      className="course-details-container mobile-view"
    >
      <DataWrapper
        className="info-item"
        isEmpty={hasNoRegistrationDeadline}
        empty=""
      >
        <div className="info-item _courses-date">
          Register until {registrationDeadline}
        </div>
      </DataWrapper>
      <DataWrapper className="info-item" isEmpty={hasNoStartDate} empty="">
        <div className="info-item _courses-date">Starts on {startDate}</div>
      </DataWrapper>
      <DataWrapper className="info-item" isEmpty={hasNoEndDate} empty="">
        <div className="info-item _courses-date">Ends on {endDate}</div>
      </DataWrapper>

      <DataWrapper className="info-item" isEmpty={hasNoLocation} empty="">
        <SpriteIconViewer spriteId={location.icon} />
        <div className="label">{location.name}</div>
      </DataWrapper>
      <DataWrapper className="info-item" isEmpty={hasNoDuration} empty="">
        <SpriteIconViewer spriteId={'schedule'} />
        {duration}
      </DataWrapper>
      <DataWrapper className="info-item" isEmpty={hasNoLevel} empty="">
        <SpriteIconViewer spriteId={'signal_cellular_alt'} />
        {level}
      </DataWrapper>
      <DataWrapper className="info-item" isEmpty={hasNoPricingModel} empty="">
        <SpriteIconViewer spriteId={'confirmation_number'} />
        {pricingModel}
      </DataWrapper>
    </DataWrapper>
  );
};

export default CourseMobileInfo;
