import React from 'react';
import Tag from 'antd/lib/tag';

import { LEVEL_1, LEVEL_2, LEVEL_3 } from '@helpers/constants';

const SkillTag = ({ name, level, key }) => {
  const getSkillColor = (competency) => {
    switch (competency) {
      case LEVEL_1:
        return LEVEL_1.toLowerCase();
      case LEVEL_2:
        return LEVEL_2.toLowerCase();
      case LEVEL_3:
        return LEVEL_3.toLowerCase();
      default:
        return '';
    }
  };

  return (
    <>
      <Tag className={`skill-tag ${getSkillColor(level)}`} key={key}>
        {`${name}`}
      </Tag>
    </>
  );
};

export default SkillTag;
