import React from 'react';

const DataWrapper = ({
  className = '',
  isError = false,
  error = <div> Error data </div>,
  isLoading = false,
  loading = <div>Loading data... </div>,
  isEmpty = false,
  empty = <div> No data </div>,
  children = <></>,
}) => {
  if (isError) return <>{error}</>;
  if (isLoading) return <>{loading}</>;
  if (isEmpty) return <>{empty}</>;
  return <div className={`${className}`}>{children}</div>;
};

export default DataWrapper;
