import React from 'react';
import CourseMobileInfo from '@templates/CoursesContentViewer/CourseDetails/MobileInfo';
import CourseDesktopInfo from '@templates/CoursesContentViewer/CourseDetails/CourseDesktopInfo';

const CourseDetails = (props) => {
  return (
    <>
      <CourseDesktopInfo {...props} />
      <CourseMobileInfo {...props} />
    </>
  );
};

export default CourseDetails;
