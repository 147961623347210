import getLocationIcon from '@templates/CoursesContentViewer/helpers/methods/getLocationIcon';
import getLocationName from '@templates/CoursesContentViewer/helpers/methods/getLocationName';
import getLocationType from '@templates/CoursesContentViewer/helpers/methods/getLocationType/index.tsx';
import getSponsorLabel from '@templates/CoursesContentViewer/helpers/methods/getSponsorLabel';
import formatDate from '@helpers/methods/formatDate';
import calculateDuration from '@helpers/methods/calculateDuration';

import selectAuthor from '@helpers/methods/modifyContents/utils/selectAuthor';
import selectAuthorLogoUrl from '@helpers/methods/modifyContents/utils/selectAuthorLogoUrl';
import selectContentImage from '@helpers/methods/modifyContents/utils/selectContentImage';

const useCourseDetailsData = (course) => {
  const registrationDeadline = formatDate(course?.registration_deadline);
  const startDate = formatDate(course?.start_date);
  const endDate = formatDate(course?.end_date);
  const level = course?.level?.label;
  const pricingModel = getSponsorLabel(course?.cost);
  const duration = calculateDuration(course?.duration)?.label;
  const locationIcon = getLocationIcon(course?.location);
  const locationName = getLocationName(course?.location);
  const locationType = getLocationType(course?.location);

  let courseDetailsData;

  courseDetailsData = selectAuthor(courseDetailsData, course);
  courseDetailsData = selectAuthorLogoUrl(courseDetailsData, course);
  courseDetailsData = selectContentImage(courseDetailsData, course);

  courseDetailsData = {
    ...courseDetailsData,

    registrationDeadline,
    startDate,
    endDate,
    level,
    pricingModel,
    duration,
    location: {
      icon: locationIcon,
      name: locationName,
      type: locationType,
    },
  };

  return courseDetailsData;
};

export default useCourseDetailsData;
