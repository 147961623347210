import moment from 'moment';

const formatDate = (strapiDate) => {
  const date = moment(strapiDate, ['YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss']);

  const isDateInvalid = !date.isValid();
  if (isDateInvalid) return '';

  const formattedDate = date.format('MMM DD, YYYY');
  return `${formattedDate}`;
};

export default formatDate;
