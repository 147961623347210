import React from 'react';
import DataWrapper from '@components/DataWrapper';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import isEmpty from 'lodash/isEmpty';

const LessInfoDesktop = ({
  registrationDeadline,
  startDate,
  endDate,
  level,
  pricingModel,
  duration,
  location,
}) => {
  const hasNoRegistrationDeadline = isEmpty(registrationDeadline);
  const hasNoStartDate = isEmpty(startDate);
  const hasNoEndDate = isEmpty(endDate);
  const hasNoLocation = isEmpty(location?.name);
  const hasNoDuration = isEmpty(duration);
  const hasNoLevel = isEmpty(level);
  const hasNoPricingModel = isEmpty(pricingModel);
  const hasNoContent =
    hasNoRegistrationDeadline &&
    hasNoStartDate &&
    hasNoEndDate &&
    hasNoLocation &&
    hasNoDuration &&
    hasNoLevel &&
    hasNoPricingModel;
  return (
    <DataWrapper
      empty=""
      isEmpty={hasNoContent}
      className="course-details-container less-info"
    >
      <DataWrapper
        className="less-info-item"
        isEmpty={hasNoRegistrationDeadline}
        empty=""
      >
        <SpriteIconViewer spriteId={'calendar'} />
        <div className="less-info-item ">
          Register until {registrationDeadline}
        </div>
      </DataWrapper>
      <DataWrapper className="less-info-item" isEmpty={hasNoStartDate} empty="">
        <SpriteIconViewer spriteId={'calendar'} />

        <div className="less-info-item ">Starts on {startDate}</div>
      </DataWrapper>
      <DataWrapper className="less-info-item" isEmpty={hasNoEndDate} empty="">
        <SpriteIconViewer spriteId={'calendar'} />

        <div className="less-info-item ">Ends on {endDate}</div>
      </DataWrapper>

      <DataWrapper className="less-info-item" isEmpty={hasNoLocation} empty="">
        <SpriteIconViewer spriteId={location?.icon} />
        <div className="label">{location?.name}</div>
      </DataWrapper>
      <DataWrapper className="less-info-item" isEmpty={hasNoDuration} empty="">
        <SpriteIconViewer spriteId={'schedule'} />
        {duration}
      </DataWrapper>
      <DataWrapper className="less-info-item" isEmpty={hasNoLevel} empty="">
        <SpriteIconViewer spriteId={'signal_cellular_alt'} />
        {level}
      </DataWrapper>
      <DataWrapper
        className="less-info-item"
        isEmpty={hasNoPricingModel}
        empty=""
      >
        <SpriteIconViewer spriteId={'confirmation_number'} />
        {pricingModel}
      </DataWrapper>
    </DataWrapper>
  );
};

export default LessInfoDesktop;
