import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Image from '@components/Image';
import MarkdownViewer from '@components/MarkdownViewer';
import SkillTags from '@components/SkillTags';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout';
import SocialSharing from '@components/SocialSharing';
import CardCategory from '@components/Cards/CardCategory';
import InquiryModal from '@components/InquiryModal';
import AuthorPagePreview from '@components/AuthorPagePreview';
import CourseDetails from '@templates/CoursesContentViewer/CourseDetails';
import useCourseDetailsData from '@templates/CoursesContentViewer/helpers/hooks/useCourseDetailsData';
import useCourseInquiryModalData from '@templates/CoursesContentViewer/helpers/hooks/useCourseInquiryModalData';
import getCourseCardsProps from '@helpers/methods/modifyContents/getCourseCardsProps';
import DesktopAds from '@components/ContentViewer/Advertisement/DesktopAds';
import MobileAds from '@components/ContentViewer/Advertisement/MobileAds';
import VideoFrame from '@components/VideoFrame';
import Audio from '@components/Audio';
import getAbsoluteURL from '@helpers/methods/getAbsoluteURL';
import formatPapuaDate from '@helpers/methods/formatZuluTime';
import getSponsorName from './helpers/methods/getSponsorName';
import selectAuthorProfileUrl from '@helpers/methods/modifyContents/utils/selectAuthorProfileUrl';

const Courses = ({ data, location }) => {
  const coursesInquiryModalProps = {
    postURL: `${process.env.GATSBY_API_URL}/course-inquiries`,
    link: `${process.env.GATSBY_SITE_URL}/career-development/courses/${data?.strapiCourses?.slug}`,
  };

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const dashboardAuthor = data?.strapiAuthorPages;
  const course = data?.strapiCourses;
  const metaImage =
    course?.metaImage?.localFile?.childImageSharp?.resize ?? null;

  const courseDetailsData = useCourseDetailsData(course);
  const courseInquiryModalData = useCourseInquiryModalData(course);
  const relatedCoursesData = getCourseCardsProps(data?.relatedCourses?.nodes);

  const apiAuthorName = courseDetailsData?.author;
  const apiAuthorDisplay = apiAuthorName ? `by ${apiAuthorName}` : '';
  const audioExt = course?.audio?.ext;
  const audioAbsoluteURL = getAbsoluteURL(
    course?.audio?.publicURL,
    course?.audio?.url
  );
  const sampleDate = formatPapuaDate(course?.published_date);
  const hasNoSampleDate = !sampleDate;
  const actualDate = formatPapuaDate(course?.published_at);
  const courseDate = hasNoSampleDate ? actualDate : sampleDate;
  const sponsoredCoursesName = getSponsorName(course?.cost);

  const coverImageUrl = course?.cover_image?.url
    ? `${process.env.GATSBY_API_URL}${course?.cover_image?.url}`
    : null;

  const thumbnailImageUrl = course?.thumbnail_image?.url
    ? `${process.env.GATSBY_API_URL}${course?.thumbnail_image?.url}`
    : null;

  return (
    <Layout
      path={location?.pathname}
      className="courses"
      testimonials={course?.testimonial}
      relatedCourses={relatedCoursesData}
    >
      <SEO title={course?.title} metaImage={metaImage} description="Courses" />
      <div className="courses-page-section _section-padding">
        <div className="courses-page">
          <div className="content-and-side-ads-container">
            <div className="content-column">
              <div className="image-container">
                <Image
                  className="desktop-image"
                  image={
                    courseDetailsData?.cover_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || coverImageUrl
                  }
                />
                <Image
                  className="phone-image"
                  image={
                    courseDetailsData?.cover_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || thumbnailImageUrl
                  }
                />
              </div>

              <div className="header-wrapper">
                <CardCategory label={course?.courses_category?.name} />
                <h1 className="course-title">{course?.title}</h1>
                <div className="author-info">
                  <span className="author-label">
                    {apiAuthorDisplay}
                    <span className="card-dot _text_grayed_out">&middot;</span>
                    <span>{courseDate}</span>
                  </span>
                </div>
              </div>
              <div className="sponsor-name-container">
                {sponsoredCoursesName}
              </div>

              <CourseDetails {...courseDetailsData} />

              <InquiryModal
                {...coursesInquiryModalProps}
                visible={isVisibleModal}
                item={courseInquiryModalData}
                setIsVisibleModal={setIsVisibleModal}
              />

              <Audio url={audioAbsoluteURL} ext={audioExt} />

              <MarkdownViewer
                className={'article-view'}
                source={course?.content}
              />

              <VideoFrame url={course?.video_url} />
              <SocialSharing path={location?.pathname} title={course?.title} />
              <SkillTags skills={course?.skills} />
            </div>

            <DesktopAds />
          </div>

          {!!apiAuthorName ? (
            <>
              <AuthorPagePreview
                authorName={apiAuthorName}
                logo={courseDetailsData?.authorLogoUrl}
                profileLink={selectAuthorProfileUrl([data?.strapiCourses])}
              />
            </>
          ) : (
            <>
              <AuthorPagePreview
                tagLine={dashboardAuthor?.company_tagline}
                authorName={dashboardAuthor?.company_name}
                logo={
                  dashboardAuthor?.avatar?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                profileLink={selectAuthorProfileUrl([dashboardAuthor])}
              />
            </>
          )}

          <MobileAds />
        </div>
      </div>
    </Layout>
  );
};

export default Courses;

export const query = graphql`
  query CoursesQuery(
    $id: Int!
    $categoryId: Int
    $title: String
    $authorId: Int
  ) {
    strapiCourses(strapiId: { eq: $id }) {
      video_url
      strapiId
      creator_email
      updater_email
      title
      slug
      registration_deadline
      start_date
      end_date
      duration
      level {
        value
        label
      }

      cost {
        label
        type
        sponsorName: sponsor_name
        sponsorLogo: sponsor_logo {
          localFile {
            extension
            publicURL
          }
        }
      }
      skills {
        name
        level
      }
      location {
        province {
          name
        }
        type
        city
        address
      }
      content
      created_at(formatString: "MMMM DD, YYYY")
      published_date
      published_at

      creator_id

      created_by {
        username
      }
      courses_category {
        name
      }
      tags {
        name
      }

      metaImage: cover {
        localFile {
          childImageSharp {
            resize(width: 1200, height: 628, quality: 100) {
              src
              width
              height
            }
          }
        }
        url
      }

      cover_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      cover_image_old: cover {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }

      thumbnail_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
                transformOptions: { cropFocus: CENTER }
                layout: FULL_WIDTH
                aspectRatio: 1
              )
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
                transformOptions: { cropFocus: CENTER }
                layout: FULL_WIDTH
                aspectRatio: 1
              )
            }
          }
          url
        }
      }
      thumbnail_image_old: thumbnail {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
              aspectRatio: 1
            )
          }
        }
        url
      }

      audio {
        publicURL
        url
        ext
      }
      testimonial {
        id
        name
        occupation
        quotation
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                width: 60
                height: 60
                quality: 100
                layout: CONSTRAINED
              )
            }
          }
          url
        }
      }
    }
    relatedCourses: allStrapiCourses(
      limit: 6
      sort: { fields: published_date, order: DESC }
      filter: {
        title: { ne: $title }
        courses_category: { id: { eq: $categoryId } }
        content_status: { value: { eq: "2" } }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        duration
        registration_deadline
        start_date
        end_date
        level {
          value
          label
        }
        cost {
          label
          type
          sponsorName: sponsor_name
          sponsorLogo: sponsor_logo {
            localFile {
              extension
              publicURL
            }
          }
        }
        courses_category {
          name
        }
        location {
          type
          province {
            name
          }
        }

        creator_id
        author {
          username
        }
        created_by {
          username
        }
      }
    }
    strapiAuthorPages(created_by: { id: { eq: $authorId } }) {
      avatar {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
              aspectRatio: 1
            )
          }
        }
        url
      }
      company_name
      company_tagline
      slug
    }
  }
`;
