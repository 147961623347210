const getSponsorName = (costs) => {
  const DEFAULT_NAME = null;
  const isSponsorsEmpty = costs?.length === 0;
  const isNotArrayName = !Array.isArray(costs);
  const isSponsorsInvalid = isSponsorsEmpty || isNotArrayName;
  if (isSponsorsInvalid) return DEFAULT_NAME;
  const sponsorName = costs[0]?.sponsorName;
  return sponsorName ? `Sponsored by ${sponsorName}` : null;
};

export default getSponsorName;
