import React from 'react';
import DataWrapper from '@components/DataWrapper';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import LessInfoDesktop from './LessInfoDesktop';
const CourseDesktopInfo = (props) => {
  const {
    registrationDeadline,
    startDate,
    endDate,
    level,
    pricingModel,
    duration,
    location,
  } = props;

  const hasNoRegistrationDeadline = isEmpty(registrationDeadline);
  const hasNoStartDate = isEmpty(startDate);
  const hasNoEndDate = isEmpty(endDate);
  const hasNoLocation = isEmpty(location?.name);
  const hasNoDuration = isEmpty(duration);
  const hasNoLevel = isEmpty(level);
  const hasNoPricingModel = isEmpty(pricingModel);
  const hasNoContent =
    hasNoRegistrationDeadline &&
    hasNoStartDate &&
    hasNoEndDate &&
    hasNoLocation &&
    hasNoDuration &&
    hasNoLevel &&
    hasNoPricingModel;
  const isOnsite = location?.type === 'onsite';
  const hasNoLocationOnsite = hasNoLocation || !isOnsite;
  const hasLocationOnsite = hasNoLocation || isOnsite;

  const checkNullObect = (item) => {
    if (item.name === null) return item.icon === null;
    return item;
  };

  const courseDetails = [
    registrationDeadline,
    startDate,
    endDate,
    level,
    pricingModel,
    duration,
    checkNullObect(location),
  ];
  const compactDetails = compact(courseDetails);
  if (compactDetails.length <= 4) return <LessInfoDesktop {...props} />;
  if (compactDetails.length > 4)
    return (
      <DataWrapper
        empty=""
        isEmpty={hasNoContent}
        className="course-details-container desktop-view _spacer-lg  "
      >
        <DataWrapper
          className={`info-item ${location?.type}`}
          isEmpty={hasNoLocationOnsite}
          empty=""
        >
          <SpriteIconViewer spriteId={location?.icon} />
          <p className="label">{location?.name}</p>
        </DataWrapper>
        <DataWrapper
          className={'info-item'}
          isEmpty={hasNoRegistrationDeadline}
          empty=""
        >
          <SpriteIconViewer spriteId={'calendar'} />
          <div className={'info-label'}>
            Register until {registrationDeadline}
          </div>
        </DataWrapper>

        <DataWrapper className={'info-item'} isEmpty={hasNoStartDate} empty="">
          <SpriteIconViewer spriteId={'calendar'} />
          <div className={'info-label'}>Starts on {startDate}</div>
        </DataWrapper>
        <DataWrapper className={'info-item'} isEmpty={hasNoEndDate} empty="">
          <SpriteIconViewer spriteId={'calendar'} />
          <div className={'info-label'}>Ends on {endDate}</div>
        </DataWrapper>

        <DataWrapper className="info-item" isEmpty={hasNoDuration} empty="">
          <SpriteIconViewer spriteId={'schedule'} />
          <p className={'info-label'}>{duration}</p>
        </DataWrapper>

        <DataWrapper className="info-item" isEmpty={hasNoLevel} empty="">
          <SpriteIconViewer spriteId={'signal_cellular_alt'} />
          <p className={'info-label'}>{level}</p>
        </DataWrapper>

        <DataWrapper className="info-item" isEmpty={hasNoPricingModel} empty="">
          <SpriteIconViewer spriteId={'confirmation_number'} />
          <p className={'info-label'}>{pricingModel}</p>
        </DataWrapper>
        <DataWrapper className="info-item" isEmpty={hasLocationOnsite} empty="">
          <SpriteIconViewer spriteId={location?.icon} />
          <p className="label">{location?.name}</p>
        </DataWrapper>
      </DataWrapper>
    );

  return <></>;
};

export default CourseDesktopInfo;
