import formatDate from '@helpers/methods/formatDate';
import getSponsorName from '../../methods/getSponsorName';
import selectAuthor from '@helpers/methods/modifyContents/utils/selectAuthor';

const useCourseInquiryModalData = (course) => {
  let courseDetailsData;

  courseDetailsData = selectAuthor(courseDetailsData, course);

  const title = course?.title;
  const username = courseDetailsData?.author;
  const registrationDeadline = formatDate(course?.registration_deadline);
  const startDate = formatDate(course?.start_date);
  const slug = course?.slug;
  const creator_email = course?.creator_email;
  const sponsorName = getSponsorName(course?.cost);

  return {
    title,
    username,
    registrationDeadline,
    startDate,
    slug,
    creator_email,
    sponsorName,
  };
};

export default useCourseInquiryModalData;
